import styled, { css } from "styled-components";

export const Root = styled.div`
   position: relative;
   width: 90%;
`;

export const baseButtonMixin = css`
   background: none;
   border: none;
   padding: 0;
`;

export const ValueWrapper = styled.input`
   width: 100%;
   padding-left: 8px;
   padding-right: 32px;
   height: 32px;
   box-sizing: border-box;
   border-radius: 5px;
   border: 2px solid #294765;
   line-height: 32px;
`;

export const AutoCompleteIcon = styled.span`
   position: absolute;
   top: 0;
   right: 0;
   height: 32px;
   width: 32px;
   transition: all 150ms linear;
   transform: ${(props: any) => (props.isOpen ? "rotate(0.5turn)" : "none")};
   transform-origin: center;
   display: flex;

   svg {
      margin: auto;
   }

   ${ValueWrapper}:focus + & {
      color: ${(props: any) => props.color || "0063cc"};
      fill: ${(props: any) => props.fill || "0063cc"};
   }
`;

export const AutoCompleteContainer = styled.ul`
   background: #fff;
   padding: 8px 0;
   list-style-type: none;
   min-width: 90%;
   position: absolute;
   top: 100%;
   left: 0;
   border: 1px solid #b6c1ce;
   border-radius: 2px;
   margin: 0;
   box-sizing: border-box;
   max-height: 280px;
   overflow-y: auto;
   z-index: 1;
`;

export const AutoCompleteItem = styled.li`
   padding: 0 24px;
   width: 100%;
   box-sizing: border-box;
   &:hover {
      background-color: #eeeeee;
   }
`;

export const AutoCompleteItemButton = styled.button`
   ${baseButtonMixin} width: 100%;
   line-height: 32px;
   text-align: left;
   &:active {
      outline: none;
      color: #aaa;
   }
`;
export const Input = styled(ValueWrapper)`
   transition: border-color 150ms linear;

   &:focus {
      border-color: #0063cc;
      outline: none;

      + ${AutoCompleteIcon} {
         color: ${(props: any) => props.color || "0063cc"};
         fill: ${(props: any) => props.fill || "0063cc"};
      }
   }
`;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../redux/store";
import { fetchSession } from "./sessionAPI";

export interface SessionState {
   loginDisplay: number | any; // 0 hide login, 1 show login, 2 show logout
   loginDisplayLastClicked: number | string | undefined; // 0=close 1=logout, 2=login/info
   paused: boolean;
   toggle: boolean;
   status: "idle" | "loading" | "failed";
   user: any;
   value: number;
}

const initialState: SessionState | any = {
   loginDisplay: 0,
   loginDisplayLastClicked: 0,
   paused: false,
   toggle: true,
   status: "idle",
   user: {
      token: "",
      email: "",
      displayName: "",
      photoUrl: "",
      uid: "",
      createdAt: "",
      creationTime: "",
      lastLoginAt: "",
      lastSignInTime: "",
   },
   value: 0,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(addAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const addAsync = createAsyncThunk(
   "./fetchSession",
   async (amount: number) => {
      const response = await fetchSession(amount);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
   }
);

export const sessionSlice = createSlice({
   name: "session",
   initialState,
   // The `reducers` field lets us define reducers and generate associated actions
   reducers: {
      setSession: (state, action: PayloadAction<any>) => {
         const o: object | any = action.payload;
         try {
            state.user = o.user;
            state.loginDisplay = o.loginDisplay;
            state.paused = o.paused;
            state.loginDisplayLastClicked = o.loginDisplayLastClicked;
            state.status = o.status;
            state.value = o.value;
         } catch (error) {
            console.log(error);
         }
      },
      clearSession: (state) => {
         state.user = { token: "", email: "" }; // initialState;
         state.loginDisplay = 1; // 0 or -1, no login, 1 login, 2 show logout
         state.loginDisplayLastClicked = 2;
      },
      // Use the PayloadAction type to declare the contents of `action.payload`
   },
   // The `extraReducers` field lets the slice handle actions defined elsewhere,
   // including actions generated by createAsyncThunk or in other slices.
   extraReducers: (builder) => {
      builder
         .addCase(addAsync.pending, (state) => {
            state.status = "loading";
         })
         .addCase(addAsync.fulfilled, (state) => {
            state.status = "idle";
            //state.value += action.payload;
         })
         .addCase(addAsync.rejected, (state) => {
            state.status = "failed";
         });
   },
});

export const { setSession, clearSession } = sessionSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.session.value)`
export const selectSession = (state: RootState) => state.session.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const sessionInit =
   (user: any): AppThunk =>
   (dispatch) => {
      dispatch(setSession(user));
   };

export default sessionSlice.reducer;
